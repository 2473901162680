import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import SectionHeading from '../components/sectionHeading'
import "../styles/index.scss"
import { SEO } from "../components/seo"

const MatterPort = props => {
  return (
    <Layout pageTitle="Home Page">
        <div className="mainContent">
            <div className="matterportContainer">
                <div className="matterportItem">
                    <iframe src="https://www.zillow.com/view-3d-home/e80643d3-5c11-4744-9fc7-4e07e638850c?setAttribution=mls&amp;wl=true&amp;utm_source=dashboard" height="matterport" width="400" frameborder="0" allowfullscreen=""></iframe>
                </div> 
                <div className="matterportItem">
                    <iframe src="https://www.zillow.com/view-3d-home/13ea4455-be9d-470f-92bb-2766c2b01cee?setAttribution=mls&amp;wl=true&amp;utm_source=dashboard" height="matterport" width="400" frameborder="0" allowfullscreen=""></iframe>
                </div> 
                <div className="matterportItem">
                    <iframe src="https://www.zillow.com/view-3d-home/89867415-a054-45b9-a2fb-3dbd45da4603?setAttribution=mls&amp;wl=true&amp;utm_source=dashboard" height="matterport" width="400" frameborder="0" allowfullscreen=""></iframe>
                </div> 
            </div>
        </div>
    </Layout>
  )
}

export default MatterPort;

export const Head = () => (
    <SEO title="360 examples" />
  )